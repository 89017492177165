.control-room-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "player chart"
    "controls metrics"
    "config connection";
  grid-gap: 10px;
}

.control-room-player {
  grid-area: player;
  background-color: black;
  padding-bottom: 56.25%; /* preserves the aspect ratio for 16:9 */
  // border: 1px solid black;
  position: relative;
  .control-room-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .instructions {
    position: absolute;
    bottom: 0px;
    text-align: center;
    font-weight: bold;
    color: red;
    background-color: silver;
    .bigger {
      font-size: 1.3em;
    }
  }
  .counts {
    .viewer-percentage {
      background-color: blue;
      border-radius: 5px;
      color: white;
      font-weight: bold;
      padding: 2px 5px;
      display: inline-block;
      margin-right: 10px;
    }
    .viewers {
      background-color: green;
      border-radius: 5px;
      color: white;
      font-weight: bold;
      padding: 2px 5px;
      display: inline-block;
      margin-right: 10px;
    }
    .tickets {
      background-color: red;
      border-radius: 5px;
      color: white;
      font-weight: bold;
      padding: 2px 5px;
      display: inline-block;
    }
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
  }
  .countdown {
    .countdown-holder {
      background-color: black;
      border-radius: 5px;
      .digit {
        display: inline-block;
        z-index: 10;
      }

      li {
        display: inline-block;
        font-size: 0.7em;
        font-weight: 400;
        list-style-type: none;
        padding: 0.5em;
        text-transform: uppercase;
      }

      li span {
        display: block;
        font-size: 0.8rem;
        font-weight: 700;
      }
    }
    position: absolute;
    text-align: center;
    color: white;
    font-weight: bold;
    top: 0;
    left: 0;
    right: 0;
    max-width: 160px;
    margin: auto;
    padding: 10px;
  }
  .states {
    .video-state {
      color: white;
      border-radius: 5px;
      padding: 2px 5px;
      margin-bottom: 10px;
    }
    .live {
      background-color: red;
    }
    .scheduled {
      background-color: silver;
      color: gray;
    }
    .marketing {
      background-color: red;
      color: white;
      border-radius: 5px;
      padding: 2px 5px;
      margin-bottom: 10px;
    }
    .message {
      background-color: $brand;
      color: white;
      border-radius: 5px;
    }
    text-align: center;
    font-weight: bold;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
  }
  .video-message {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    color: white;
    text-align: center;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  img {
    width: 100%;
  }
  .transcoder-state {
    color: white;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .service-btn {
    display: flex;
    min-width: 300px;
    max-width: 400px;
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

.watermark-btn {
  cursor: pointer;
  background-color: #000;
  border-radius: 6px;
}

.control-room-chart {
  grid-area: chart;
}

.control-room-controls {
  grid-area: controls;
  padding: 10px;
}

.control-room-metrics {
  grid-area: metrics;
  padding: 10px;
}

.control-room-connection {
  grid-area: connection;
  padding: 10px;
}

.control-room-config {
  grid-area: config;
  padding: 10px;
}

@media (max-width: 992px) {
  .control-room-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      "player"
      "controls"
      "chart"
      "metrics"
      "config"
      "connection";
  }
}

.marketing-section {
  .counter {
    font-family: "Space Mono", monospace;
    font-weight: 700;
    font-style: normal;
  }
}