.videos-header {
  align-self: center;
}

.sort-fields {
  display: flex;
  align-items: center;
}

.video-list a:hover {
  text-decoration: none;
}

.video-list .widget-heading {
  color: $gray-700;
}

.video-list .widget-subheading {
  color: $gray-700;
}

.video-list .content-start-time {
  color: $gray-700;
}

.video-list-wrapper:hover {
  background: $dropdown-link-hover-bg;
  transition: transform 0.2s, height 300ms, color 300ms, background-color 300ms,
    -webkit-transform 0.2s;
}
.list-wrapper:hover {
  background: $dropdown-link-hover-bg;
  transition: transform 0.2s, height 300ms, color 300ms, background-color 300ms,
    -webkit-transform 0.2s;
}

.search-fields {
  display: flex;
}

.search-field-dropdown {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0.1rem 1.5rem 0.1rem 0.5rem;
}

.search-field-dropdown-divider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.5rem;
  // padding-left: 0.3rem;
}

.dropdown-menu-nowrap {
  white-space: nowrap;
}

.video-type-save-btn {
  margin-left: auto;
}

.content-plus-icon {
  margin-right: 0.5rem;
  font-size: 13px;
  transition: color 0.1s;
  display: inline-block;
}

.content-arrow-icon {
  cursor: pointer;
}
.content-arrow-icon:hover {
  background-color: #e9ecef;
  border-radius: 0.65rem;
}

.content-pagination {
  display: flex;
  justify-content: center;
}

.date-range-picker-save {
  display: flex;
  justify-content: center;
}

.save-date-button {
  margin-bottom: 0.3em;
}

.drp-bottom-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.65rem;
  padding-left: 0.65rem;
  padding-right: 0.65rem;
}

.content-heading {
  opacity: 0.8;
  font-weight: bold;
}

.content-subheading {
  opacity: 0.5;
}

.capitalize {
  text-transform: capitalize;
}

.content-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.center-actions {
  align-self: center;
  flex-direction: row;
}

.facilities-select__menu {
  :hover {
    background-color: #deebff;
  }
}

.facilities-select__menu-list {
  padding: 0.2rem 0.8rem 0.2rem 0.8rem;
}

.player-background {
  text-align: center;
}

.remove-inline {
  display: block;
}

.placeholder-content {
  text-align: center;
}

.stream-service {
  margin-left: 0.1rem;
}

.stream-service-text {
  margin-right: 1rem;
}

.content-icon {
  font-size: 1.5rem;
}

.radio-margin {
  margin-top: 0.5rem;
}

.syd-item {
  cursor: pointer;
  transition: transform 0.2s, height 300ms, color 300ms, background-color 300ms,
    -webkit-transform 0.2s;
}

.syd-item:hover {
  background-color: $dropdown-link-hover-bg;
}

.video-content-rbt-menu {
  .rbt-menu li {
    padding: 0.2rem;
  }

  .rbt-menu {
    .divider {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

.duration-badge {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 4px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0 4px;
  border-radius: 2px;
}

.content-video-badge {
  position: absolute;
  top: 71px;
  right: 15px;
  margin: 4px;

  &.live {
    color: white;
    background-color: rgba(0, 128, 0, 0.8);
  }
  &.vod {
    color: black;
    background-color: rgba(255, 165, 0, 0.8);
  }
}

.content-img-container {
  position: relative;
  width: auto;
  padding-left: 0;
}

.content-div-checkbox {
  position: absolute;
  display: flex;
  align-content: center;
  justify-content: space-around;
  background: transparent;
  width: 35px;
  height: 35px;
  z-index: 2;
}

.content-checkbox {
  left: 10px !important;
}

// ImageDropZone

.imageDropZone-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: dashed;
  border-width: 2px;
  transition: all 0.2s;
  background-color: $white;
  cursor: pointer;
  max-width: 100%;
}

.imageDropZone-container:hover {
  border-color: $primary;
}

.imageDropZone-inner {
  margin: 0;
  text-align: center;
  padding: 80px 20px;
  line-height: 1.4;
}

.imageDropZone-note {
  font-size: 1em;
  color: lighten($gray-900, 10%);
}

.imageDropZone-restrictions {
  font-size: 0.9em;
  color: lighten($gray-600, 10%);
}

.imageDropzZone-remove-btn {
  display: none;
}

img:hover + .imageDropzZone-remove-btn,
.imageDropzZone-remove-btn:hover {
  display: inline-block;
}

.uppy-Dashboard-inner {
  border: 2px dashed #eaeaea;
}

[data-uppy-drag-drop-supported="true"] .uppy-Dashboard-AddFiles {
  border: none;
}

.uppy-Dashboard-browse {
  color: $link-color;
}

.uppy-Dashboard-AddFiles-title {
  color: $gray-700;
}

@media screen and (max-width: 768px) {
  .search-fields {
    display: flex;
    align-items: flex-start;
    flex: 1;
    flex-direction: column;
  }
}

@media screen and (max-width: 576px) {
  .content-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .sort-fields {
    margin-top: 8px;
  }
}
