// User Pages

.domain-category-item {
  background-color: white;
  padding: 1em 1em;
  border-bottom: $card-border-width solid $card-border-color;
}

.content-item {
  background-color: white;
  padding: 1em 1em;
  border-bottom: $card-border-width solid $card-border-color;
}

.page-item {
  display: flex;
  justify-content: space-between;
}

.primary-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.draggable-background {
  background-color: silver;
}

.controls {
  cursor: pointer;
}

.select-box {
  display: flex;
  flex-wrap: wrap;
  .select-box-item {
    background-color: silver;
    border: 1px solid black;
    flex-grow: 1;
    padding: 10px;
    .select-label {
      font-weight: bold;
    }
    .select-description {
      font-size: 0.8em;
    }
  }
}

.domain-edit-icon {
  padding: 4px;
  &:hover {
    background-color: #e9ecef;
    border-radius: 12px;
  }
}

.items-counter {
  min-width: 20px;
  background-color: rgba(209, 213, 218, 0.5);
  border-radius: 2em;
  border: 1px solid transparent;
  padding: 0 4px;
  text-align: center;
}

#content-item-search .dropdown-item {
  display: block;
  padding: 0.25rem 0;
}

.hide-icon {
  display: none;
}

.item-container:hover .hide-icon {
  display: block;
}

.close-button {
  border-radius: 12px;
  width: 24px;
  height: 24px;
}

.live-banner {
  background-color: green;
  color: white;
}

.vod-banner {
  background-color: orange;
  color: black;
}

.linear-banner {
  background-color: rgb(47,79,79);
  color: white;
}

.series-banner {
  background-color: purple;
  color: white;
}

.domain-banner {
  background-color: #B22222;
  color: white;
}

.venue-banner {
  background-color: #6495ED;
  color: white;
}

.page-banner {
  background-color: #FFD700;
  color: black;
}

.dragging-card {
  .hide-section {
    display: none;
  }
}

.drag-border {
  border: 5px solid red;
}
