
@media (min-width: 768px) {
  .coupon-off {
    margin-top: -27px;
  }
}



.coupon-required-passes button:not(:disabled):not(.disabled):active:focus, .coupon-required-passes > .btn-outline-secondary.dropdown-toggle {
  color: rgb(108, 117, 125);
  background-color: rgb(0,0,0,0);
  border-color: rgb(108, 117, 125);
}

.coupon-required-passes {
  width: 100%;
  height: 40px;
}

.coupon-required-passes button {
  text-align: left;
}

.coupon-pass-remove:hover {
  cursor: pointer;
}

.coupon-pass-remove:hover {
  color: #545cd8
}