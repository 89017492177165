// NOTE: the order of these styles DO matter

.CalendarDay__default {
  border: none;
}
// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: rgb(247, 247, 247); //background
  color: $black; //text
  border: 1px solid rgb(247, 247, 247); //default styles include a border
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: rgb(247, 247, 247);
  color: $black;
  border: 1px double $black;
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: $brand;
  color: $white;
}

.CalendarDay__selected:active {
  background: rgb(34, 34, 34);
  color: $white;
  border: 1px double $gray-400;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: $brand;
  color: $white;
  border: 1px double rgb(113, 113, 113);
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: rgb(247, 247, 247);
  color: $black;
  border: 1px solid rgb(247, 247, 247);
}

.DateInput_input {
  font-weight: 400;
}

.DateInput_input__focused {
  border-bottom: 2px solid $brand;
}
.DateRangePickerInput__withBorder {
  border-radius: 0.25rem;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid $brand;
}

.DayPickerKeyboardShortcuts_show__topRight::before {
  border-right: 33px solid $brand;
}

.PresetDateRangePicker_panel {
  padding: 0 0 24px 20px;
}

.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: none;
  border: 2px solid $brand;
  border-radius: 3px;
  color: $brand;
  padding: 4px 12px;
  margin-right: 8px;
  font-weight: 500;
  line-height: normal;
  overflow: visible;
  box-sizing: border-box;
  cursor: pointer;

  &:active {
    outline: 0;
  }
}

.PresetDateRangePicker_button__selected {
  color: #fff;
  background: $brand;
}

.DayPickerNavigation_button__horizontalDefault {
  top: 25px;
}

.DayPickerNavigation_leftButton__horizontalDefault {
  left: 14px;
}

.DayPickerNavigation_rightButton__horizontalDefault {
  right: 14px;
}
