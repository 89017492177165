// General

a,
button,
.btn {
  outline: none !important;
}

.app-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0;
}

.text-primary {
  color: #545cd8 !important;
}

.text-right, .text-md-right {
  text-align: right !important;
}

.text-left, .text-mid-left {
  text-align: left !important;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

a.metismenu-link {
  text-decoration: none;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-bolder {
  font-weight: 900;
}
