// Token used in Bootstrap AsyncTypeahead
.custom-token {
  color: $white;
  background-color: $brand;
  font-weight: 500;

  .rbt-token-remove-button {
    color: $white;
    font-weight: 500;
  }

  &-tag {
    color: $white;
    background-color: $dark;
  }
  &-domain {
    color: $white;
    background-color: $red;
  }
  &-producer {
    color: $black;
    background-color: $success;

    .rbt-token-remove-button {
      color: $black;
    }
  }
  &-year {
    color: $white;
    background-color: $purple;
  }
  &-facility {
    color: $black;
    background-color: $info;

    .rbt-token-remove-button {
      color: $black;
    }
  }
}

.rbt-input-multi .rbt-input-wrapper {
  margin-top: 0;
}
